import { AuthenticatedApp, UnauthenticatedApp } from './scenes/apps'
import { useAppSelector } from './redux-state/hooks'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css'
import './App.css';

function App() {
  const auth = useAppSelector((state) => state.auth)
  return (
    <BrowserRouter>
      {auth && auth.user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  );
}

export default App;

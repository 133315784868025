import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CopyToClipboardButton from '../../components/CopyToClipboard/CopyToClipboardButton'


const EventLinkToast = ({message}: {message: string}) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
        <CopyToClipboardButton textToCopy={message}/>
        <Typography>Copy attendance link</Typography>
    </Box>
  )
}

export default EventLinkToast
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import dayjs from 'dayjs'
import { AxiosError } from 'axios'

import { TextField, Typography, Box, StandardTextFieldProps, Button, styled, Fade, useTheme } from "@mui/material"
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import { Formik, useField, Form } from "formik"
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { toast, ToastContentProps } from 'react-toastify'
import { Player } from '@lottiefiles/react-lottie-player';
import * as Yup from 'yup'

import Event from '../../models/Event'
import { AuthUser } from '../../redux-state/slices/authSlice'
import AttendanceService from '../../services/AttendanceService'
import AuthService from '../../services/AuthService'
import { decodeJWT, axiosErrToMessage } from "../../services/utils"
import { SCJ_NUM_EXACT_REGEX } from '../../services/constants'
import EventService from '../../services/EventService'

const LoginButton = styled(Button)(props => ({
  background: "linear-gradient(90deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);",
  borderRadius: '1.5em'
}))

interface RegInputProps extends StandardTextFieldProps {
  name: string
}

const RegInput = ({name, ...props}: RegInputProps) => {
  const [field, meta] = useField(name)

  return(
    <TextField variant='standard' fullWidth sx={{pb: "2rem", fontSize: "1.5rem"}}
      error={meta.touched && meta.error !== undefined}
      helperText={meta.touched && meta.error ? meta.error : ''}
      {...field} {...props}/>
  )
}

const RegistrationPage = () => {
  const {eventToken} = useParams()
  const theme = useTheme()
  const [event, setEvent] = useState<Event | null>(null)
  const [user, setUser] = useState<AuthUser | null>(null)
  const [submissionSuccess, setSubmissionSuccess] = useState(false)

  const formatDate = (date: string) => dayjs(date).format('YYYY.MM.DD - HH:mm:ss')

  useEffect(() => {
    if(eventToken){
      EventService.decodeToken(eventToken)
        .then(eventData => setEvent(eventData))
        .catch(e => {
          toast.error(e.response.data.message || e.message)
          setEvent(null)
        })
    }
  }, [eventToken])
  

  return (
    <Box height='100vh' display='grid' justifyContent='center' alignItems='center' gridTemplateColumns='min(90%, 500px)' sx={{
      background: "linear-gradient(135deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);"
    }}>
      {!submissionSuccess ? <Box bgcolor='white' borderRadius='20px' minHeight='100px' display='flex' flexDirection='column' alignItems='center' p='2rem 1.5rem 2rem 1.5rem'>
        <Typography variant='h3' color='black' sx={{mb: "1rem"}}>Attendance</Typography>
        <Typography variant='h6' color='black' sx={{mb: "1rem"}}>{event ? `${event.title}   -   ${formatDate(event.startDate)}` : "No event"}</Typography>
        <Formik
          initialValues={{scjNumber: '', password: ''}}
          validationSchema={Yup.object({
            scjNumber: Yup.string().required("Champ obligatoire").matches(SCJ_NUM_EXACT_REGEX, "Format invalide"),
            password: Yup.string().required("Champ obligatoire")
          })}
          onSubmit={(values, {setSubmitting}) => {
            if(!event){
              toast.error("Lien invalide")
              return;
            }
            toast.promise(
              AuthService.authenticate(values.scjNumber, values.password)
                .then(authToken => {
                  setUser(decodeJWT(authToken))
                  return AttendanceService.submit(eventToken || "", authToken)
                })
                .then(res => {
                      setSubmissionSuccess(true)
                      setSubmitting(false)
                }),
              {
                pending: "En cours d'envoi",
                error: {render(res: ToastContentProps<AxiosError<string, any>>) {return res.data ? 
                  (res.data.response?.status == 401 ? "Identifiant ou mot de passe incorrect" : axiosErrToMessage(res.data) )
                  : 'Erreur'}}
              }
            )
            
        }}>
          <Form css={{display: 'contents'}}>
              <RegInput name='scjNumber' disabled={!event} placeholder='Numéro SCJ'/>
              <RegInput name='password' disabled={!event} type='password' placeholder='Mot de passe'/>
              <LoginButton type='submit' 
                disabled={!event}
                sx={{color: 'white', fontWeight: 800, width: '100%', fontSize: "1.3rem", fontStyle: 'bold'}}>
                  envoyer
              </LoginButton>
          </Form>
        </Formik>
      </Box> : 
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' >
        <Player
          autoplay
          keepLastFrame
          src="/assets/icons/animated/done.json"
          style={{ height: '100%', width: '100%' }}
        />
        <Fade in={submissionSuccess} timeout={3000}>
          <Paper sx={{ bgcolor: 'rgba(255,255,255, 1)', border: `solid 4px #22A55F`, borderRadius: '4px' }}>
            <Typography sx={{textAlign: 'center'}}>Participation Enregistrée !</Typography>
            <TableBody>
                <TableRow>
                    <TableCell>{user && user.name}</TableCell>
                    <TableCell>{event && event.title}</TableCell>
                    <TableCell>{dayjs(event && event.startDate).format('YYYY.MM.DD - HH:mm:ss')}</TableCell>
                </TableRow>
            </TableBody>
          </Paper>
        </Fade>
      </Box>}
    </Box>
  )
}

export default RegistrationPage
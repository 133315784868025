import { useState } from 'react'

import { Box, Stack, Typography, useTheme,  Button, Dialog, DialogActions, DialogContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid/components/cell/GridActionsCellItem'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'

import { Scheduler } from '@aldabil/react-scheduler'
import { ProcessedEvent, ViewEvent } from '@aldabil/react-scheduler/types'

import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContentProps } from 'react-toastify'
import { AxiosError } from 'axios'

import { DATE_BACK_FORMAT } from '../../services/constants'
import { axiosErrToMessage } from '../../services/utils'
import EventService from '../../services/EventService'
import Event, {emptyEvent} from '../../models/Event'
import ResourceDataTable from '../../components/ResourceDataTable'
import EventForm from './EventForm'
import IconSwitch from '../../components/Switch/IconSwitch'
import EventPreview from './EventPreview'

enum DisplayMode {
    LIST,
    CALENDAR
}


const EventListPage = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [updateData, setUpdateData] = useState(1)
    const [mode, setMode] = useState<DisplayMode>(DisplayMode.CALENDAR)
    const [eventToDelete, setEventToDelete] = useState<null | Event>(null)
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false)

    const openRemoveDialog = (event: Event) => {
        setEventToDelete(event)
        setIsRemoveDialogOpen(true)
    }
    const closeRemoveDialog = () => {
        setEventToDelete(null)
        setIsRemoveDialogOpen(false)
    }

    const deleteEvent = (eventId: string | number) => {
        return toast.promise(EventService.deleteEvent(eventId)
            .then(res => {
            setUpdateData(prev=> prev + 1)
            setIsRemoveDialogOpen(false)
            }), {
                pending: 'Deleting event ...',
                success: `Event deleted`,
                error:  {render(res: ToastContentProps<AxiosError<string, any>>) {
                  return res.data ? axiosErrToMessage(res.data) : 'Error'
                }},
              }
        )
    }

    const fetchRemote = async (query: ViewEvent): Promise<ProcessedEvent[]> => {
        const filters = [
            {columnField: "startDate", operatorValue: ">", value: dayjs(query.start).format(DATE_BACK_FORMAT)},
            {columnField: "startDate", operatorValue: "<", value: dayjs(query.end).format(DATE_BACK_FORMAT)}
        ]
        return EventService.getEvents(0, 100, [{field: "startDate", sort: "desc"}], {items: filters})
            .then(res => res.content.map((event: Event) => ({
                event_id: event.id,
                start: dayjs(event.startDate).toDate(),
                end: dayjs(event.startDate).add(1, 'hours').toDate(),
                ...event
            })))
      }


    return (
        <Box p={{xs: '1em', md:'5em'}}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h3'>Events</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>Calendar</Typography>
                        <IconSwitch 
                            onChange={e => setMode(e.target.checked  ? DisplayMode.LIST : DisplayMode.CALENDAR)}
                            size='medium'
                            iconCheckedUrl='/assets/icons/bulleted_list.svg' 
                            iconUncheckedUrl='/assets/icons/calendar.svg'
                            checked={mode === DisplayMode.LIST}
                            />
                        <Typography>List</Typography>
                    </Stack>
            </Box>
            {mode === DisplayMode.LIST ? 
                <>
                    <ResourceDataTable<Event> 
                        key={updateData}
                        onRowClick={(params) => navigate(`/admin/dashboard/${params.row.id}`, {state: {event: params.row}})}
                        rowGetter={(page, pageSize, sort, filters) => EventService.getEvents(page, pageSize, sort, filters)}
                        columns={[
                            {flex: 1, field: 'title', headerName: 'Title'}, 
                            {flex: 1, field: 'type.name', headerName: 'Type', valueGetter: (params) => params.row.type.name}, 
                            {flex: 1, field: 'startDate', headerName: 'Date', valueFormatter: (params) => dayjs(params.value).format('YYYY.MM.DD')}, 
                            {align: 'center', field: 'actions', headerName: 'Delete', type: 'actions',  width: 80, getActions: (params) => [
                                    <GridActionsCellItem
                                    icon={<DeleteIcon />}
                                    label="Remove"
                                    onClick={() => openRemoveDialog(params.row)}
                                    />]
                                },

                        ]}
                        initialState={{pagination: {pageSize: 50}}}
                    />
                    <Dialog onClose={closeRemoveDialog} open={isRemoveDialogOpen}>
                        <IconButton sx={{position: 'absolute', top: 0, right: 0}} size='small' onClick={closeRemoveDialog}><CloseIcon/></IconButton>
                        <DialogContent sx={{pt: '3em'}}>
                            <Typography variant='h5'>Do you want to delete this event ?</Typography>
                            {eventToDelete && <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={eventToDelete.id}> 
                                            <TableCell>{eventToDelete.title}</TableCell>
                                            <TableCell>{dayjs(eventToDelete.startDate).format('YYYY.MM.DD')}</TableCell>
                                            <TableCell>{eventToDelete.type.name}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>}
                        </DialogContent>
                        <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button 
                                sx={{background: theme.palette.gradient.error || theme.palette.error.main}} 
                                variant='contained' onClick={closeRemoveDialog}>No</Button>
                            <Button 
                                sx={{background: theme.palette.gradient.success || theme.palette.success.main}} 
                                variant='contained' onClick={() => deleteEvent(eventToDelete ? eventToDelete.id : '')}>Yes, delete event</Button>
                        </DialogActions>
                    </Dialog>
                </> : 
                <Scheduler
                    key={updateData}
                    view="month"
                    week={{ 
                        weekDays: [0, 1, 2, 3, 4, 5], 
                        weekStartOn: 6, 
                        startHour: 9, 
                        endHour: 23,
                        step: 60,
                        navigation: true,
                        disableGoToDay: false
                        }}
                    getRemoteEvents={fetchRemote}
                    customEditor={(scheduler) => <EventForm 
                        onSubmit={() => setUpdateData(prev=> prev + 1)} 
                        onUpdate={() => setUpdateData(prev=> prev + 1)} 
                        scheduler={scheduler} />}
                    viewerExtraComponent={(fields, event) => <EventPreview fields={fields} event={{...event, id: event.event_id}}/>}
                    onDelete={(id) => deleteEvent(id)}
                />
            }
        </Box>
    )
}

export default EventListPage
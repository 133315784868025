import Box from '@mui/material/Box'
import React from 'react'
import { NAVBAR_HEIGHT, NAVBAR_PHONE_HEIGHT } from './Navbar'

interface ContentProps {
    children: React.ReactNode
}
const Content = ({children}: ContentProps) => {
  return (
    <Box sx={{pt: {xs: NAVBAR_PHONE_HEIGHT, md: NAVBAR_HEIGHT}}}>
      {children}
    </Box>
  )
}

export default Content
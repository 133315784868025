import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import authReducer from '../redux-state/slices/authSlice'
import eventsReducer from '../redux-state/slices/eventsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    events: eventsReducer
  },
})

// Inferred type: {auth: AuthState, massages: massagesState}
export type AppDispatch = typeof store.dispatch
// Infer the `RootState` and `AppDispatch` types from the store itself

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>


import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel'
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel'

import { api, filtersToQuery } from './utils'

/**
 * This class is in charge of all the requests for regarding the Attendance entity
 */
class AttendanceService {

  /**
   * Get event attendees list
   */
  getEventAttendees(eventId: string | number, page: number, perPage: number, sort: GridSortModel, filters: GridFilterModel) {
    return api.get(`/attendance/event/${eventId}?page=${page}&pageSize=${perPage}`, {
      params: {
        filter: filtersToQuery(filters),
        sortField: sort[0] ? sort[0].field : "id",
        sortOrder: sort[0] ? sort[0].sort : "asc"
      }
    })
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  /**
   * Get ALL event attendees list
   */
  getAllEventAttendees(eventId: string | number, filters: GridFilterModel) {
    return api.get(`/attendance/event/${eventId}/all`, {
      params: {
        filter: filtersToQuery(filters),
      }
    })
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  /**
   * Get event absents list
   */
  getEventAbsents(eventId: string | number, page: number, perPage: number, sort: GridSortModel, filters: GridFilterModel) {
    return api.get(`attendance/event/${eventId}/non-participants?page=${page}&pageSize=${perPage}`, {
      params: {
        filter: filtersToQuery(filters),
        sortField: sort[0] ? sort[0].field : "id",
        sortOrder: sort[0] ? sort[0].sort : "asc"
      }
    })
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  /**
   * Get ALL event absents list
   */
  getAllEventAbsents(eventId: string | number, filters: GridFilterModel) {
    return api.get(`/attendance/event/${eventId}/non-participants/all`, {
      params: {
        filter: filtersToQuery(filters),
      }
    })
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  /**
   * Given an ID get the stats of an Event
   * @param eventId 
   * @returns 
   */
  getEventStats(eventId: string | number) {
    return api.get(`/attendance/event/${eventId}/stats`)
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  /**
   * Submit my attendance for an event
   */
  submit(attendanceToken: string, authToken: string) {
    return api.post(`/attendances/fill-attendance/short-token/${attendanceToken}`, {}, { headers: { Authorization: `Bearer ${authToken}` } })
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }
}

export default new AttendanceService()

import { createTheme } from '@mui/material/styles';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    gradient: {
      main: string
      error: string
      success: string
    }
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    gradient?: {
      main: string
      error: string
      success: string
    }
  }
}


export const mainTheme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#FDBB2D',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#22C1C3'
    },
    warning: {
      main: '#FFC400'
    },
    info: {
      main: '#5a88d1'
    },
  }
});


export const gradientTheme = createTheme(mainTheme, {
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#FDBB2D',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#22C1C3'
    },
    warning: {
      main: '#FFC400'
    },
    info: {
      main: '#5a88d1'
    },
    gradient: {
      main: `linear-gradient(135deg, ${mainTheme.palette.secondary.main} 0%,${mainTheme.palette.primary.main} 100%)`,
      error: `linear-gradient(135deg, #ff9a9e 0%, #fad0c4 100%)`,
      success: `linear-gradient(135deg, #cff4d2 0%, #7be495 100%)`
    }
  }
});



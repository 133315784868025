import { GridFilterModel } from "@mui/x-data-grid";
import axios, { AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import AuthService from "./AuthService";
import { API_SERVER_BASE_URL } from './constants'

/**
 * Instance of axios that logout the user when receiving a 401 error 
 */
export const api = axios.create({
  baseURL: `${API_SERVER_BASE_URL}/`,
})

api.interceptors.request.use(function (config) {
  const token = AuthService.getAuthToken()
  if(!config.headers.Authorization)
    config.headers.Authorization =  token ? `Bearer ${token}` : null
  return config
})

api.interceptors.response.use((response) => response, (error) => {
  const statusCode = error.response ? error.response.status : null
  if (statusCode === 401) {
    if(error.response.data.message == "Invalid token")
      AuthService.logout()
  }
  throw error;
})

export const axiosErrToMessage = (err: AxiosError<any, any>) => {
  if(err.response){
    if(err.response.data && err.response.data.length)
      return err.response.data
    if(err.response.data && err.response.data.message)
      return err.response.data.message
  }
  return err.message
}

/**
   * Decode a given token
   * @param token 
   * @returns 
   */
 export const decodeJWT = <T>(token: string | null): T | null => {
  return token ? jwtDecode(token) : null
}

/**
 * Interface for paginated resources
 */
export interface Paginator<T> {
  content: T[];
  // pageable: {
  //   sort: {
  //     sorted: boolean;
  //     unsorted: boolean;
  //     empty: boolean;
  //   };
  //   offset: number;
  //   pageNumber: number;
  //   pageSize: number;
  //   unpaged: boolean;
  //   paged: boolean;
  // };
  totalPages: number;
  totalElements: number;
  // last: boolean;
  // first: boolean;
  // sort: {
  //   sorted: boolean;
  //   unsorted: boolean;
  //   empty: boolean;
  // };
  // numberOfElements: number;
  // size: number;
  // number: number;
  // empty: boolean;
}

/**
 * This object converts the operator names used by MUI DataTables into names that can be understood by the backend counterpart
 */
 export const operatorDic : {[key: string]: string} = {
  'contains': "contains",
  'equals': ":",
  'startsWith': "*",
  'endsWith': "**",
  'isEmpty': "isEmpty",
  'isNotEmpty': "isNotEmpty",
  'isAnyOf': "isAnyOf",
  'is': ":",
  '=': ":",
  '!=': "!",
  '>': ">",
  '<': "<",
  '>=': ">=",
  '<=': "<=",
}

/**
 * Given a set of filters (according to MUI data table format) 
 * return a filter URL query ready to be parsed by the backend
 * @param filters 
 */
export const filtersToQuery = (filters: GridFilterModel): string => {
  const filtersQueryList = filters.items.map(filter => {
    return filter.columnField + operatorDic[filter.operatorValue || "="] + filter.value
  })
  return filtersQueryList.join(',')
}
export const jsonToCsv = (objList: any[], headers: {jsonKey: string, headerName: string}[]) => {
  const headerKeys = headers.map(header => header.jsonKey)
  let csv = headers.map(header => header.headerName).join(',')+'\n'
  objList.forEach(rowData =>{
    csv += headerKeys.map(key => _.get(rowData, key)).join(',')+'\n'
  })
  return csv
}


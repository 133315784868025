import { Box } from "@mui/material"
import { Navigate, Route, Routes } from "react-router-dom"
import LoginPage from "../Login/LoginPage"
import RegistrationPage from "../Registration/RegistrationPage"

const UnauthenticatedApp = () => {
  
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', width: '100%', flexDirection: 'column' }}>
        <Routes>
            <Route path='/login' element={<LoginPage/>}/>
            <Route path='/submit/:eventToken' element={<RegistrationPage/>}/>
            <Route path='/*' element={<Navigate to='/login'/>}/>
        </Routes>
    </Box>
  )
}

export default UnauthenticatedApp

import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import React from "react";



interface IconSwitchProps extends SwitchProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    iconCheckedUrl?: string
    iconUncheckedUrl?: string
}

const StyledIconSwitch = styled(({iconCheckedUrl, iconUncheckedUrl, ...props}: IconSwitchProps) => <Switch {...props}/>)<IconSwitchProps>(({ theme, iconCheckedUrl, iconUncheckedUrl }) => ({
    width: 75,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(35px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: iconCheckedUrl ? `url("${iconCheckedUrl}")` : 'unset',
          backgroundSize: "cover",
          borderRadius: "50%",
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: iconUncheckedUrl ? `url("${iconUncheckedUrl}")` : 'unset',
        backgroundSize: "cover",
        borderRadius: "50%",
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

const IconSwitch = ({onChange, iconCheckedUrl, iconUncheckedUrl, ...props}: IconSwitchProps) => {
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        onChange(event)
    };
    return (<StyledIconSwitch 
            checked={checked}  
            onChange={handleChange} 
            iconCheckedUrl={iconCheckedUrl} 
            iconUncheckedUrl={iconUncheckedUrl} 
            {...props}/>)
}
export default IconSwitch;
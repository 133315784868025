
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel'
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel'

import dayjs from 'dayjs'

import { api, filtersToQuery } from './utils'
import Event from '../models/Event'

import { DATE_BACK_FORMAT } from './constants'

/**
 * This class is in charge of all the requests for regarding the Event entity
 */
class EventService {

  /**
   * Get events list
   * @param page 
   * @param perPage 
   * @param sort 
   * @param filters 
   * @returns 
   */
  getEvents(page: number, perPage: number, sort: GridSortModel, filters: GridFilterModel) {
    return api.get(`/event?page=${page}&pageSize=${perPage}`, {
      params: {
        filter: filtersToQuery(filters),
        sortField: sort[0] ? sort[0].field : "id",
        sortOrder: sort[0] ? sort[0].sort : "asc"
      }
    })
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  /**
   * Fetch all the possible types of event
   * @returns 
   */
  getEventTypes() {
    return api.get(`/event/types`)
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  /**
   * Given an Id get one Event
   * @param eventId 
   * @returns 
   */
  getEventById(eventId: string | number) {
    return api.get(`/event/${eventId}`)
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  generateToken(eventId: string | number, expirationDate: string) {
    return api.get(`/event/short-token/${eventId}/${expirationDate}`)
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  decodeToken(token: string) {
    return api.get(`/events/short-token/decode/${token}`)
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  /**
   * Post a new event
   * @param event 
   * @returns 
   */
  postEvent(event: Event) {
    return api.post(`/event`, {
      ...event,
      id: null,
      createdAt: dayjs().format(DATE_BACK_FORMAT),
      updatedAt: dayjs().format(DATE_BACK_FORMAT)
    })
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  /**
   * Update event
   * @param event 
   * @returns 
   */
  updateEvent(event: Event) {
    return api.put(`/event`, {
      ...event,
      updatedAt: dayjs().format(DATE_BACK_FORMAT)
    })
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  deleteEvent(eventId: string | number) {
    return api.delete(`/event/${eventId}`)
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }
}

export default new EventService()

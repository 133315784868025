import React, { useEffect } from 'react'

import { ThemeProvider } from '@mui/material'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { getMe, isAdmin } from '../../redux-state/slices/authSlice'

import Navbar from '../../components/Layout/Navbar'
import Content from '../../components/Layout/Content'

import { useAppDispatch } from '../../redux-state/hooks'
import { gradientTheme } from '../../themes'
import PrivateRoute from '../../components/Authorization/PrivateRoute'
import RegistrationPage from '../Registration/RegistrationPage'
import EventListPage from '../Events/EventListPage'
import { getEventTypes } from '../../redux-state/slices/eventsSlice'
import DashboardPage from '../Attendance/DashboardPage'



const AuthenticatedApp = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getEventTypes({}))
        dispatch(getMe({}))
    }, [])
    
    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', width: '100%', flexDirection: 'column' }}>
            <Routes>
                <Route path='/' element={<Navigate to="/admin/events"/>}/>
                <Route path='/submit/:eventToken' element={<RegistrationPage/>}/>

                {/* here are the admin routes */}
                <Route path='/admin/*' element={
                    <PrivateRoute isAuthorized={isAdmin}>
                        <ThemeProvider theme={gradientTheme}>
                            <Navbar/>
                            <Content>
                                <Outlet/>
                            </Content>
                        </ThemeProvider>
                    </PrivateRoute>
                }> 
                    <Route path="events" element={<EventListPage />} /> 
                    <Route path="dashboard/:eventId" element={<DashboardPage />} /> 
                    <Route path="dashboard/:eventId/:detail" element={<DashboardPage />} /> 
                </Route>
                <Route path='/*' element={<Navigate to="/admin/events"/>}/>
            </Routes>
        </Box>
    )
}

export default AuthenticatedApp

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import User, {Role} from '../../models/User'
import AuthService from '../../services/AuthService'


export interface AuthUser extends Pick<User, 'id' | 'scjNumber' | 'callName' | 'roles'> {
  name?: string
}

export const isAdmin = (user: AuthUser) => Role[Role.ROLE_ATTENDANCE_ADMIN] in user.roles

// Declare global variable in orer to access authenticated user from Laravel without aving a type error
declare global {
  interface Window {
      User:AuthUser;
  }
}

type AuthState = {
  user: AuthUser | null
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  message: string
}



const getCurrentUser = () => {
  return AuthService.decodeAuthToken(AuthService.getAuthToken())
}

const initialState: AuthState = {
  user: getCurrentUser(),
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
}


// Login user
export const login = createAsyncThunk<
  any,
  { scjNumber: string; password: string },
  { rejectValue: string }
>(
  'auth/login',
  ({ scjNumber, password }: { scjNumber: string; password: string }, thunkApi) => {
    return AuthService.login(scjNumber, password).catch((err) => {
      const msg =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString()
      return thunkApi.rejectWithValue(err.body ? err : msg)
    })
  }
)

// Get currently logged in user
export const getMe = createAsyncThunk<
  any,
  { },
  { rejectValue: string }
>(
  'auth/getMe',
  ({ }, thunkApi) => {
    return AuthService.getMe().catch((err) => {
      const msg =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString()
      return thunkApi.rejectWithValue(err.body ? err : msg)
    })
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
      state.user = null
    },
    logout: (state) => {
      AuthService.logout()
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
      state.user = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
        state.isError = false
        state.message = ''
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload ? action.payload : 'Error occured'
        state.user = null
        state.isSuccess = false
      })
      .addCase(getMe.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = {...state.user, ...action.payload}
        state.isError = false
        state.message = ''
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload ? action.payload : 'Error occured'
        state.user = null
        state.isSuccess = false
      })
  },
})

export const { reset, logout } = authSlice.actions
export default authSlice.reducer

import React, {useEffect, useState} from 'react'
import { toast } from 'react-toastify';
import { DataGrid, DataGridProps, GridColumns, GridEventListener, GridFilterItem, GridFilterModel, GridSortDirection, GridSortModel, GridValidRowModel } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles';
import { Paginator } from '../../services/utils';
import { useLocation, useSearchParams } from 'react-router-dom';


const StyledDataGrid = styled(DataGrid)(({onRowClick, theme}) => ({
    '&': {
        border: 0,
        borderBottom: '1px solid black',
    },
    '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell': {
        border: 0,
        borderBottom: '1px solid black',
        fontSize: '1.2em',
        fontWeight: 800,
        fontFamily: 'Inter',
        [theme.breakpoints.down('md')]: {
            fontSize: '12px'
        }
    },
    '& .MuiDataGrid-cell:hover': {
        cursor: onRowClick ? 'pointer': 'unset'
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
}))



interface Props<T> extends Omit<DataGridProps, 'rows'> {
    columns: GridColumns
    rowGetter: (page: number, pageSize: number, sort: GridSortModel, filters: GridFilterModel) => Promise<Paginator<T>>
    onRowClick?: GridEventListener<'rowClick'>
}
const ResourceDataTable = <T extends GridValidRowModel>({columns, rowGetter, onRowClick, onFilterModelChange = ()=>{}, ...props}: Props<T>) => { 
    const [rows, setRows] = useState<T[]>([])
    const [rowCountState, setRowCountState] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    
    const [pageSize, setPageSize] = useState(props.initialState?.pagination?.pageSize || 5)
    const [page, setPage] = useState(props.initialState?.pagination?.page || 0)
    const [sort, setSort] = useState(props.initialState?.sorting?.sortModel || [])
    const [filters, setFilters] = useState<GridFilterModel>(props.initialState?.filter?.filterModel || {items: []})
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    
    const updateSearchParams = () => {
        setSearchParams({
            pagination: JSON.stringify({page, pageSize}),
            filters: JSON.stringify(filters.items),
            sorts: JSON.stringify(sort)
        }, {replace: true})
    }

    useEffect(()=> {       
        if(searchParams.has('pagination') || searchParams.has('sort') || searchParams.has('filter'))
            updateSearchParams()
        // get rows
        setIsLoading(true)
        rowGetter(page, pageSize, sort, filters)
            .then(res => {
                setRows(res.content)
                setRowCountState(res.totalElements)
                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false)
                toast.error(err.response.statusText)
            })
    }, [page, pageSize, sort, filters])

    useEffect(() => {
        if(location.search.length > 0){
            const query = new URLSearchParams(location.search)
            
            // search pagination (page,pageSize) and set it
            const newPagination = query.get('pagination')
            if(newPagination){
                const {page: newPage, pageSize: newPageSize} = JSON.parse(newPagination)
                setPage(parseInt(newPage))
                setPageSize(parseInt(newPageSize))
            }
            // search sort (field,sort) and set it
            const sortsString = query.get('sorts')
            if(sortsString){
                const newSorts: {field: string, sort: GridSortDirection}[] = JSON.parse(sortsString).flatMap((sort: {field: string, sort: GridSortDirection}) => {
                    return sort.field && sort.sort ? sort : []
                })
                if(newSorts.length)
                    setSort(newSorts)
            }
            // search filters (id,columnField,operatorValue,value) and set it
            const filtersString = query.get('filters')
            if(filtersString){
                const newFilters: GridFilterItem[] = JSON.parse(filtersString).flatMap((filter: GridFilterItem) => {
                    return filter.id && filter.columnField && filter.operatorValue && filter.value ? filter : []
                })
                if(newFilters.length)
                    setFilters({items: newFilters})
            }
        }
    }, [])
    
    return (
        <StyledDataGrid
            autoHeight
            loading={isLoading}
            rowCount={rowCountState}
            rows={rows}
            columns={columns}
            onRowClick={onRowClick}
            disableSelectionOnClick 
            paginationMode="server"
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setPage(newPage)}
            page={page}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            sortingMode="server"
            onSortModelChange={(sortModel) => setSort(sortModel.length ? sortModel : [])}
            sortModel={sort}
            filterMode="server"
            onFilterModelChange={(filterModel, details) => {setFilters(filterModel);onFilterModelChange(filterModel, details)}}
            filterModel={filters}
            {...props}
        />
    )
}

export default ResourceDataTable
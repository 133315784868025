
import { TOKEN_KEY } from './constants'

import { AuthUser, isAdmin } from '../redux-state/slices/authSlice';
import { api, decodeJWT } from './utils';

/**
 * This class is in charge of all the requests for authentication and authorization
 */
class AuthService {

  /**
   * Get the authentication token if any
   * @returns token
   */
  getAuthToken(){
    return localStorage.getItem(TOKEN_KEY);
  }

  /**
   * Decode a given token
   * @param token 
   * @returns 
   */
  decodeAuthToken(token: string | null): AuthUser | null{
    return token ? decodeJWT<AuthUser>(token) : null
  }

  /**
   * Send a login request to the API
   */
  login(scjNumber: string, password: string) {
    return api.post('auth/authenticate', {scjNumber, password})
      .then((res) => {
        // if the request was successful, check if the received data has a token, if yes then store it in local storage and pass the data along
        const json = res.data
        if (json[TOKEN_KEY]) {
          const userInfo = this.decodeAuthToken(json[TOKEN_KEY])
          if(userInfo && isAdmin(userInfo)){
            localStorage.setItem(TOKEN_KEY, json[TOKEN_KEY])
            return userInfo
          } else {
            throw "Unauthorized"
          }
        }
        throw "No token"
      })
      .catch((err) => Promise.reject(err))
  }

  /**
   * Send a login request to the API but this time it only returns the token and doesn't store it in local storage
   */
   authenticate(scjNumber: string, password: string) {
    return api.post('auth/authenticate', {scjNumber, password})
      .then((res) => res.data[TOKEN_KEY])
      .catch((err) => Promise.reject(err))
  }

  /**
   * Get infos about the authenticated user
   */
  getMe(){
    return api.get('members/me')
      .then(res => res.data)
      .catch((err) => Promise.reject(err))
  }

  /**
   * Logout the user by removing the token from local storage
   */
  logout() {
    localStorage.removeItem(TOKEN_KEY)
    window.location.reload()
  }
}

export default new AuthService()

import React from 'react';
import {Navigate, Params, useParams} from 'react-router-dom'
import { useAppSelector } from '../../redux-state/hooks';
import { AuthUser } from '../../redux-state/slices/authSlice';

interface PrivateRouteProps {
    children: React.ReactNode
    isAuthorized: (user: AuthUser, urlParams: Params) => boolean
}
const PrivateRoute = ({ children, isAuthorized }: PrivateRouteProps) => {
    const user = useAppSelector(state => state.auth.user)
    const urlParams = useParams()
    
    let authorized = false
    if(user)
        authorized = isAuthorized(user, urlParams)
    return (
        <>
            {authorized ? <>{children}</> : <Navigate to="/" replace />}
        </>
    )
};

export default PrivateRoute
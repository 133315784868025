import { IconButton, Snackbar } from '@mui/material'
import MuiTooltip from '@mui/material/Tooltip'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useState } from 'react'

interface CopyToClipboardProps {
    textToCopy: string,
    tooltipMessage?: string,
    copiedMessage?: string
}
const CopyToClipboardButton = ({textToCopy, tooltipMessage, copiedMessage}:CopyToClipboardProps) => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
      setOpen(true)
      navigator.clipboard.writeText(textToCopy)
    }
    
    return (
        <>
            <MuiTooltip title={tooltipMessage || "Copy the link"}>
                <IconButton onClick={handleClick}><ContentCopyIcon/></IconButton>
            </MuiTooltip>
            <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={2000}
            message={copiedMessage || "Copied to clipboard"}
            />
        </>
    )
}

export default CopyToClipboardButton
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { TextField, Typography, Box, StandardTextFieldProps, Button, styled } from "@mui/material"
import { Formik, useField, Form } from "formik"
import { toast, ToastContentProps } from 'react-toastify'
import * as Yup from 'yup'
import { useAppDispatch } from '../../redux-state/hooks'
import { login } from '../../redux-state/slices/authSlice'
import { SCJ_NUM_EXACT_REGEX } from '../../services/constants'

const LoginButton = styled(Button)(props => ({
  background: "linear-gradient(90deg, rgba(9,32,63,1) 0%,     rgba(83,120,149,1) 100%);",
  borderRadius: '1.5em'
}))

interface LoginInputProps extends StandardTextFieldProps {
  name: string
}

const LoginInput = ({name, ...props}: LoginInputProps) => {
  const [field, meta] = useField(name)

  return(
    <TextField variant='standard' fullWidth sx={{pb: "2rem", fontSize: "1.5rem"}}
      error={meta.touched && meta.error !== undefined}
      helperText={meta.touched && meta.error ? meta.error : ''}
      {...field} {...props}/>
  )
}

const LoginPage = () => {
  const dispatch = useAppDispatch()
  const submit = (scjNumber: string, password: string) => {
    return toast
      .promise(
        dispatch(
          login({scjNumber,password})
        ).then((action) => {
          if (action.meta.requestStatus === 'rejected') throw action.payload
        }),
        {
          pending: 'Loggin in ...',
          success: `Successfull login`,
          error:  {render(res: ToastContentProps<{response: any}>) {
            return res.data ? (res.data.response ? res.data.response.data.message : `Error: ${res.data}`) : 'Error'
          }},
        }
      )
  }
  
  return (
    <Box height='100vh' display='grid' justifyContent='center' alignItems='center' gridTemplateColumns='min(90%, 500px)' sx={{
      background: "linear-gradient(135deg, rgba(9,32,63,1) 0%,     rgba(83,120,149,1) 100%);"
    }}>
      <Box bgcolor='white' borderRadius='20px' minHeight='100px' display='flex' flexDirection='column' alignItems='center' p='2rem 1.5rem 2rem 1.5rem'>
        <Typography variant='h3' color='black' sx={{mb: "1rem"}}>Login</Typography>
        <Formik
          initialValues={{scjNumber: '', password: ''}}
          validationSchema={Yup.object({
            scjNumber: Yup.string().required().matches(SCJ_NUM_EXACT_REGEX, "Invalid format"),
            password: Yup.string().required()
          })}
          onSubmit={(values, {setSubmitting}) => {
            submit(values.scjNumber, values.password)
              .then(res => {
                setSubmitting(false)
              })
        }}>
          <Form css={{display: 'contents'}}>
              <LoginInput name='scjNumber' placeholder='SCJ number'/>
              <LoginInput name='password' type='password' placeholder='Password' />
              <LoginButton type='submit' sx={{color: 'white', fontWeight: 800, width: '100%', fontSize: "1.3rem", fontStyle: 'bold'}}>validate</LoginButton>
          </Form>
        </Formik>
      </Box>
    </Box>
  )
}

export default LoginPage
export default interface Event {
    id: string
    type: {
        id: string
        name: string
    }
    title: string
    description: string
    location: string
    startDate: string
    endDate?: string
    createdAt: string
    updatedAt: string
}

export const emptyEvent: Event = {
    id: '',
    type: {
        id: '',
        name: ''
    },
    title: '',
    description: '',
    location: '',
    startDate: '',
    endDate: '',
    createdAt: '',
    updatedAt: ''
}

export interface EventStats {
    totalMembers: number
    totalMembersYouth: number
    totalMembersMen: number
    totalMembersWomen: number
    totalMembersSenior: number
    totalMembersEducators: number
    totalParticipants: number
    totalParticipantsYouth: number
    totalParticipantsMen: number
    totalParticipantsWomen: number
    totalParticipantsSenior: number
    totalParticipantsEducators: number
}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import EventService from '../../services/EventService'


interface EventState {
    types: {id: string, name: string}[]
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
    message: string
}

const initialState: EventState = {
  types: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
}


// Fetch all the possible types of event
export const getEventTypes = createAsyncThunk<
  any,
  {  },
  { rejectValue: string }
>(
  'events/types',
  ({}, thunkApi) => {
    return EventService.getEventTypes().catch((err) => {
      const msg =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString()
      return thunkApi.rejectWithValue(err.body ? err : msg)
    })
  }
)

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
      state.types = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventTypes.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getEventTypes.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.types = action.payload
        state.isError = false
        state.message = ''
      })
      .addCase(getEventTypes.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload ? action.payload : 'Error occured'
        state.types = []
        state.isSuccess = false
      })
  },
})

export const { reset } = eventsSlice.actions
export default eventsSlice.reducer


import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { ToastContentProps, toast } from 'react-toastify';

import { saveAs } from 'file-saver';

import { isAdmin } from '../../redux-state/slices/authSlice';
import { useAppSelector } from '../../redux-state/hooks';
import ResourceDataTable from '../../components/ResourceDataTable';
import CustomTooltip from './CustomTooltip';
import AttendanceService from '../../services/AttendanceService';
import EventService from '../../services/EventService';
import { NonParticipant, Participant } from '../../models/Attendance';
import  Event, {EventStats } from '../../models/Event'
import { jsonToCsv } from '../../services/utils'
import { GridFilterModel } from '@mui/x-data-grid'


const BorderedBox = styled(Box)`
    border: solid 1px black;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
`

const SimpleTable = styled(Table)(({theme}) => ({
    '& .MuiTableCell-root': {
        border: 0,
        textAlign: 'center',
        fontWeight: 800,
        fontFamily: 'Inter',
        fontSize:'1.3em',
        padding: '0.4em',
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
            lineHeight: 'unset'
        }
    }
}))

const DashboardPage = () => {
    const user = useAppSelector(state => state.auth.user)
    const theme = useTheme()
    const {state, ...location} = useLocation()
    const navigate = useNavigate()
    const phoneSize = useMediaQuery('(max-width:600px)');
    const {eventId, detail} = useParams()
    const [event, setEvent] = useState<Event | null>(null)
    const [eventStats, setEventStats] = useState<EventStats | null>(null)
    const [loading, setLoading] = useState(true)
    const [attendeesFilters, setAttendeesFilters] = useState<GridFilterModel>({items: []})
    const [absentsFilters, setAbsentsFilters] = useState<GridFilterModel>({items: []})

    const getStats = (eventId: string) => {
        if(user && isAdmin(user))
            AttendanceService.getEventStats(eventId).then(res => setEventStats(res))
    }

    const sanitizeStat = (stat: string) => {
        return isNaN(Number(stat)) ? "No data" : stat
    }

    useEffect(() => {
        if(state && state.event){
            setEvent(state.event)
            setLoading(false)
            getStats(state.event.id)
        } else if(eventId){
            EventService.getEventById(eventId)
            .then(res => {
                setEvent(res)
                setLoading(false)
                getStats(eventId)
            })
            .catch(err => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [])
    return (
        <>{
            event ? 
            <Box 
                p={{xs: '.5em .5em 4em .5em', sm: '1em 1em 4em 1em', md:'2em 4em'}} 
                display='grid' 
                gridTemplateColumns='minmax(100px, 1200px)' 
                rowGap={{xs: '1em', sm:'2em'}} 
                alignSelf='center'
                justifyContent='center'>
                {!detail &&
                    <>
                    <Box className='title'>
                        <Typography variant={phoneSize ? 'h5' : 'h4'} color='primary' fontWeight={800} fontFamily='Inter'>{event.title}</Typography>
                    </Box>
                    <BorderedBox className='stats' p={{xs: '1em 1em', md: "1em 5em"}}>
                        <Typography variant={phoneSize ? 'h6' : 'h5'} color='primary' fontWeight={800} fontFamily='Inter' mb='.5em'>Overall participation</Typography>
                        <SimpleTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Number of participants</TableCell>
                                    <TableCell>Participation rate</TableCell>
                                    <TableCell>Absenteism rate</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {eventStats && <>
                                        <TableCell>{eventStats?.totalParticipants}/{eventStats?.totalMembers}</TableCell>
                                        <TableCell>{(eventStats?.totalParticipants*100/eventStats?.totalMembers).toFixed(2)}%</TableCell>
                                        <TableCell>{((eventStats?.totalMembers-eventStats?.totalParticipants)*100/eventStats?.totalMembers).toFixed(2)}%</TableCell>
                                    </>}
                                </TableRow>
                            </TableBody>
                        </SimpleTable>
                    </BorderedBox>
                    <BorderedBox className='charts' p={{xs: '1em 1em', md: "1em 3em"}} sx={{background:'#D9D9D9'}}>
                        <Typography variant='h6' fontWeight={800} width={{xs: '100%', md: "30%"}}>Per group attendance</Typography>
                        <Box display='flex' justifyContent='center'>
                            {eventStats && 
                            <ResponsiveContainer minWidth={300} width={phoneSize ? "100%": "60%"} height={phoneSize ? 150 : 260}>
                                <BarChart layout='vertical' margin={{top: 20,right: 20,bottom: 0,left: phoneSize ? 0 : 50}} barGap={1}
                                  data={[
                                    {name: 'youth', label: 'Jeune', value: sanitizeStat((eventStats?.totalParticipantsYouth*100/eventStats?.totalMembersYouth).toFixed(0))},
                                    {name: 'women', label: 'Femme', value: sanitizeStat((eventStats?.totalParticipantsWomen*100/eventStats?.totalMembersWomen).toFixed(0))},
                                    {name: 'men', label: 'Homme', value: sanitizeStat((eventStats?.totalParticipantsMen*100/eventStats?.totalMembersMen).toFixed(0))},
                                    {name: 'senior', label: 'Senior', value: sanitizeStat((eventStats?.totalParticipantsSenior*100/eventStats?.totalMembersSenior).toFixed(0))},
                                    {name: 'educators', label: 'Éducateurs', value: sanitizeStat((eventStats?.totalParticipantsEducators*100/eventStats?.totalMembersEducators).toFixed(0))},
                                ]}>
                                    <Tooltip wrapperStyle={{ outline: 'none' }} content={<CustomTooltip/>}/>
                                    <XAxis type="number" padding={{ left: 10 }} hide domain={[0, 100]}/>
                                    <YAxis type="category" dataKey="label" tickLine={false} strokeWidth={3} stroke="black" fontWeight={800} fontSize={phoneSize ? '.7em' : '1em'}/>
                                    <Bar dataKey="value"  fill={theme.palette.primary.main} />
                                </BarChart>
                            </ResponsiveContainer>}
                        </Box>
                    </BorderedBox>
                    </>
                }
                {(!detail || detail == 'participants') &&
                    <Box className='participants' minHeight='100px' mb='1em'>
                        <Typography variant={phoneSize ? 'h6' : 'h5'} color='primary' fontWeight={800} fontFamily='Inter'>ATTENDEES</Typography>
                        {detail && <Box display="flex" justifyContent="flex-end">
                            <Button
                                onClick={() => toast.promise(AttendanceService.getAllEventAttendees(eventId || "", attendeesFilters)
                                        .then(data => {
                                            const csvData = jsonToCsv(data, [
                                                {jsonKey: "member.name", headerName: "Name"}, {jsonKey: "member.scjNumber", headerName: "SCJ Number"},
                                                {jsonKey: "member.group", headerName: "Group"}, {jsonKey: "member.regionName", headerName: "Region"}, 
                                                {jsonKey: "createdAt", headerName: "Submission Date"}])
                                            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
                                            saveAs(blob, 'participants.csv')
                                        }),{
                                    pending: `Downloading...`,
                                    error: {render({data}: ToastContentProps<{response: any}>) {return data && `Error: ${data.response.data.message}`}}
                                })}
                                color="primary"
                                sx={{ ml: {sm: '10px'}, p: {xs: '0 0 0 0px', sm: '8px'} }}
                            >
                                <FileDownloadIcon sx={{fontSize: {xs: '1em', sm:'2em'}, fontWeight: 200}}/>
                                Export
                            </Button>
                        </Box>}
                        <ResourceDataTable<Participant> 
                            rowGetter={(page, pageSize, sort, filters) => AttendanceService.getEventAttendees(event.id, page, pageSize, sort, filters)}
                            columns={[
                                {flex: 1, field: 'member.name', headerName: 'Name', valueGetter: (params) => params.row.member.name, hide: (user && isAdmin(user)) ? false : true}, 
                                {flex: 1, field: 'member.scjNumber', headerName: 'SCJ number', valueGetter: (params) => params.row.member.scjNumber},
                                {flex: 1, field: 'member.group', headerName: 'Group', valueGetter: (params) => params.row.member.group},
                                {flex: 1, field: 'member.regionName', headerName: 'Region', valueGetter: (params) => params.row.member.regionName},
                                {flex: 1, field: 'createdAt', headerName: 'Submission date', valueFormatter: (params) => dayjs(params.value).format('YYYY.MM.DD - HH:mm:ss')},         
                            ]}
                            onFilterModelChange={(model, details) => setAttendeesFilters(model)}
                            initialState={{
                                sorting: {
                                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                                },
                              }}
                            hideFooter={detail ? false : true}/>
                        {!detail && 
                            <Box display='flex' justifyContent='end' p='1em 0 0 0'>
                                <Button
                                    onClick={()=> {
                                        const sortState = [{field: 'submission_date', sort: 'desc'}]
                                        const paginationState = {pageSize: 20, page: 0}
                                        navigate(`participants?sorts=${JSON.stringify(sortState)}&pagination=${JSON.stringify(paginationState)}`)
                                    }} 
                                    sx={{
                                        color:'white', 
                                        p: '.7em 2em',
                                        backgroundColor: theme.palette.primary.main,
                                        ':hover': {
                                            backgroundColor: theme.palette.primary.light
                                        }}}>
                                    See more
                                </Button>
                            </Box>
                        }
                    </Box>
                }
                {(!detail || detail == 'non-participants') &&
                  <Box className='non-participants' minHeight='100px'>
                      <Typography variant={phoneSize ? 'h6' : 'h5'} color='primary' fontWeight={800} fontFamily='Inter'>ABSENTS</Typography>
                      
                      {detail && <Box display="flex" justifyContent="flex-end">
                            <Button
                                onClick={() => toast.promise(AttendanceService.getAllEventAbsents(eventId || "", absentsFilters)
                                        .then(data => {
                                            const csvData = jsonToCsv(data, [
                                                {jsonKey: "name", headerName: "Name"}, {jsonKey: "scjNumber", headerName: "SCJ Number"},
                                                {jsonKey: "group", headerName: "Group"}, {jsonKey: "regionName", headerName: "Region"}])
                                            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
                                            saveAs(blob, 'non-participants.csv')
                                        }),{
                                    pending: `Downloading...`,
                                    error: {render({data}: ToastContentProps<{response: any}>) {return data && `Error: ${data.response.data.message}`}}
                                })}
                                color="primary"
                                sx={{ ml: {sm: '10px'}, p: {xs: '0 0 0 0px', sm: '8px'} }}
                            >
                                <FileDownloadIcon sx={{fontSize: {xs: '1em', sm:'2em'}, fontWeight: 200}}/>
                                Export
                            </Button>
                        </Box>}
                      <ResourceDataTable<NonParticipant> 
                          rowGetter={(page, pageSize, sort, filters) => AttendanceService.getEventAbsents(event.id, page, pageSize, sort, filters)}
                          columns={[
                            {flex: 1, field: 'member.name', headerName: 'Name', valueGetter: (params) => params.row.name, hide: (user && isAdmin(user)) ? false : true}, 
                            {flex: 1, field: 'member.scjNumber', headerName: 'SCJ number', valueGetter: (params) => params.row.scjNumber}, 
                            {flex: 1, field: 'member.group', headerName: 'Group', valueGetter: (params) => params.row.group},
                            {flex: 1, field: 'member.regionName', headerName: 'Region', valueGetter: (params) => params.row.regionName},
                          ]}
                          onFilterModelChange={(model, details) => setAbsentsFilters(model)}
                            hideFooter={detail ? false : true}/>
                      {!detail && 
                          <Box display='flex' justifyContent='end' p='1em 0 0 0'>
                              <Button 
                                  onClick={()=> navigate(`non-participants`)} 
                                  sx={{
                                      color:'white', 
                                      p: '.7em 2em',
                                      backgroundColor: theme.palette.primary.main,
                                      ':hover': {
                                          backgroundColor: theme.palette.primary.light
                                      }}}>
                                  See more
                              </Button>
                          </Box>
                      }
                  </Box>
                }
            </Box> : <Typography variant='h6'>{loading ? 'Loading' : 'No event loaded'}</Typography>
        }</>
        
    )
}

export default DashboardPage
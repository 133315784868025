import { TextField, Select, MenuItem, FormControl, FormHelperText, InputLabel, OutlinedTextFieldProps, SelectProps, FormControlProps} from '@mui/material'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker'
import  {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { useField, useFormikContext } from "formik"

import dayjs from 'dayjs'

import { DATE_BACK_FORMAT } from '../../services/constants'


export interface FormTextFieldProps extends OutlinedTextFieldProps {
    name: string
}
export const FormTextField = ({name, ...props}: FormTextFieldProps) => {
    const [field, meta] = useField(name)

    return(
        <TextField fullWidth
        error={meta.touched && meta.error !== undefined}
        helperText={meta.touched && meta.error ? meta.error : ''}
        {...field} {...props}/>
    )
}


export interface SelectItem {
    label: string
    value: any
}
export interface FormSelectProps extends SelectProps {
    name :string
    label: string
    items: SelectItem[]
    formControlProps?: FormControlProps
}
export const FormSelectField = ({name, label, items, formControlProps, ...props}: FormSelectProps) => {
    const [field, meta] = useField(name)

    return (
        <FormControl {...formControlProps}>
            <InputLabel>{label}</InputLabel>
            <Select 
                {...field} 
                {...props} 
                label={label} 
                name={name} 
                error={meta.touched && meta.error !== undefined} 
            >
                {items.map((item: SelectItem) => (
                <MenuItem key={item.label} value={item.value}>
                    {item.label}
                </MenuItem>
                ))}
            </Select>
            <FormHelperText error>{meta.touched && meta.error ? meta.error : ''}</FormHelperText>
        </FormControl>
    )
}


interface FormDateTimePickerProps extends Omit<DateTimePickerProps<unknown, Date | null>, 'onChange' | 'value' |'renderInput'> {
    name: string
    label: string
}
export const FormDateTimePicker = ({name, label,  ...props}: FormDateTimePickerProps) => {
    const [field, meta] = useField(name)
    const formik = useFormikContext<any>()

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                {...field}
                label={label}
                onChange={(value) => {
                    formik.setFieldValue(name, dayjs(value).format(DATE_BACK_FORMAT))
                }}
                renderInput={(params) => (
                    <TextField 
                    {...params} 
                    error={ formik.touched[name] && formik.errors[name] != undefined}  
                    fullWidth />
                )}
                {...props}
            />
        </LocalizationProvider>
  )
}
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { Box, Button, DialogActions, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'

import type { SchedulerHelpers } from "@aldabil/react-scheduler/types"

import { Formik, Form } from "formik"
import * as Yup from 'yup'
import { toast, ToastContentProps } from 'react-toastify'
import { AxiosError } from 'axios'

import { useAppSelector } from '../../redux-state/hooks'
import { axiosErrToMessage } from '../../services/utils'
import EventService from '../../services/EventService'
import { FormTextField, FormSelectField, FormDateTimePicker } from '../../components/Form/FormInputs'
import Event, { emptyEvent } from '../../models/Event'



const EventTextField = styled(FormTextField)(() => ({
  '&':{
    paddingBottom: "2rem",
    fontSize: "1.5rem"
  }
}))






interface EventFormProps {
    scheduler: SchedulerHelpers,
    onSubmit?: ()=>any
    onUpdate?: ()=>any
}
const EventForm = ({ scheduler, onSubmit, onUpdate }: EventFormProps) => {
  const theme = useTheme()
  const eventTypes = useAppSelector((state) => state.events.types).map(eventType =>({
    label: eventType.name,
    value: eventType
  }))
  
  const submit = (newEvent: Event) => {
      scheduler.loading(true)
      return toast.promise(
        EventService.postEvent(newEvent)
          .then(event => {
              scheduler.loading(false)
              scheduler.close()
              if(onSubmit)
                onSubmit()
              return event
          })
          .catch(err => {
            scheduler.loading(false)
            throw err
          })
      , {
        pending: 'Adding new event ...',
        success: `Event successfully added`,
        error:  {render(res: ToastContentProps<AxiosError<string, any>>) {
          return res.data ? axiosErrToMessage(res.data) : 'Error'
        }},
      })
  }

  const update = (newEvent: Event) => {
    scheduler.loading(true)
    return toast.promise(
      EventService.updateEvent(newEvent)
        .then(event => {
            scheduler.loading(false)
            scheduler.close()
            if(onUpdate)
              onUpdate()
            return event
        })
        .catch(err => {
          scheduler.loading(false)
          throw err
        })
    , {
      pending: 'Updating event ...',
      success: `Event successfully updated`,
      error:  {render(res: ToastContentProps<{response: any}>) {
        return res.data ? (res.data.response ? res.data.response.data.message : `Error: ${res.data}`) : 'Error'
      }},
    })
}
  return (
    <Box>
      <Typography sx={{p: '.5em 0 0 1em'}} variant='h5'>Event creation</Typography>
      <Formik
        initialValues={scheduler.edited ? {...emptyEvent, ...scheduler.edited, startDate: scheduler.state.start.value} : {...emptyEvent, startDate: scheduler.state.start.value}}
        validationSchema={Yup.object({
          title: Yup.string().required(),
          description: Yup.string(),
          startDate: Yup.string().required(),
          type: Yup.mixed().required()
        })}
        onSubmit={(values, {setSubmitting}) => {
          if(!scheduler.edited){
            submit(values)
              .then(res => {
                setSubmitting(false)
              })
          } else {
            update(values)
              .then(res => {
                setSubmitting(false)
              })
          }
      }}>
          <Form css={{display: 'contents'}}>
              <Box style={{ padding: "1rem" }}>
                  <EventTextField variant='outlined' name='title' label='Title' fullWidth />
                  <EventTextField variant='outlined' name='description' label='Description' fullWidth />
                  <EventTextField variant='outlined' name='location' label='Location' fullWidth />
                  <FormSelectField variant='outlined' name='type' label='Type' items={eventTypes} formControlProps={{
                    sx: {
                      pb: "2rem",
                      width: "100%"
                    }
                  }} />
                  <FormDateTimePicker  name='startDate' label='Date' />
              </Box>
              <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button sx={{background: theme.palette.gradient.error || theme.palette.error.main}} variant='contained' onClick={scheduler.close}>Cancel</Button>
                  <Button sx={{background: theme.palette.gradient.success || theme.palette.success.main}} variant='contained' type='submit'>Confirm</Button>
              </DialogActions>
          </Form>
      </Formik>
    </Box>
  );
}

export default EventForm
import {useState} from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MuiTooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import LinkIcon from '@mui/icons-material/Link'
import CloseIcon from '@mui/icons-material/Close'
import RecentActorsIcon from '@mui/icons-material/RecentActors'

import { useNavigate } from 'react-router-dom'
import { toast, ToastContentProps } from 'react-toastify'
import { Formik } from 'formik'
import * as Yup from 'yup'
import dayjs from 'dayjs'

import { FieldProps, ProcessedEvent } from '@aldabil/react-scheduler/types'
import EventService from '../../services/EventService'
import { DATE_BACK_FORMAT } from '../../services/constants'
import EventLinkToast from './EventLinkToast'
import { FormDateTimePicker } from '../../components/Form/FormInputs'
import { useTheme } from '@mui/material'


interface EventPreviewProps {
    fields: FieldProps[]
    event: ProcessedEvent
}
const EventPreview = ({fields, event}: EventPreviewProps) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [isExpDialogOpen, setIsExpDialogOpen] = useState(false)

    const closeExpDialog = () => setIsExpDialogOpen(false)
    const openExpDialog = () => setIsExpDialogOpen(true)

    const tokenToUrl = (token: string) => `${window.location.origin}/submit/${token}`

    const generateLink = (expirationDate: string) => {
        return toast.promise(
            EventService.generateToken(event.event_id, expirationDate)
                .then(res => tokenToUrl(res)), 
                {
                    success: {
                        render({data}){
                          return <EventLinkToast message={data || ""}/>
                        },
                        // other options
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    },
                    pending: "Generating link...",
                    error: {render(res: ToastContentProps<{response: any}>) {return res.data ? `Error: ${res.data}` : 'Error'}}
                  })
    }
    return (
        <Box>
            <Typography><b>Description: </b> {event.description}</Typography>
            <Box display='flex' justifyContent='space-between' sx={{mt: '1em'}}>
                <MuiTooltip title="View the attendance of this event">
                    <Box display='flex' alignItems='center'>
                        <Button
                            size='small'
                            variant='contained'
                            onClick={() => navigate(`/admin/dashboard/${event.event_id}`, {state: {event}})}
                            color="warning"
                        >
                            <RecentActorsIcon sx={{fontSize: {xs: '1.5em', sm:'2em'}, fontWeight: 200, mr: '.2em'}}/>
                            Attendance
                        </Button>
                    </Box>
                </MuiTooltip>
                <MuiTooltip title="Generate attendance link for this event">
                    <IconButton
                        onClick={openExpDialog}
                        color="info"
                        sx={{ ml: {xs: 1, sm: 5}, p: {xs: '0 0 0 3px', sm: '8px'}, }}
                    >
                        <LinkIcon sx={{fontSize: {xs: '1.5em', sm:'2em'}, fontWeight: 200}}/>
                    </IconButton>
                </MuiTooltip>
            </Box>

            <Dialog onClose={closeExpDialog} open={isExpDialogOpen}>
                <IconButton sx={{position: 'absolute', top: 0, right: 0}} size='small' onClick={closeExpDialog}><CloseIcon/></IconButton>
                <Formik
                    enableReinitialize
                    initialValues={{expirationDate: dayjs(event.start).add(4, 'hours').format(DATE_BACK_FORMAT)}}
                    validationSchema={Yup.object({
                        expirationDate: Yup.string().required('Required')
                    })}
                    onSubmit={async (values: {expirationDate: string}, { setSubmitting }) => {
                        generateLink(values.expirationDate)
                            .then(res => closeExpDialog())
                    } }
                >
                    {(formik) => 
                        <>
                            <DialogContent sx={{pt: '3em'}}>
                                <Typography sx={{mb: '1em'}} variant='h5'>Choose the expiration date of the link</Typography>
                                <FormDateTimePicker  name='expirationDate' label='Expiration date' inputFormat='YYYY/MM/DD hh:mm A' />
                            </DialogContent>
                            <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Button sx={{color: 'black', background: theme.palette.gradient.error || theme.palette.error.main}} color='error' variant='contained' onClick={closeExpDialog}>Cancel</Button>
                                <Button sx={{color: 'black', background: theme.palette.gradient.success || theme.palette.success.main}} color='success' variant='contained' type='submit' onClick={()=>formik.submitForm()}>Validate</Button>
                            </DialogActions>
                        </>
                    }
                </Formik>
            </Dialog>

        </Box>
    )
}

export default EventPreview
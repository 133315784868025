import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../redux-state/hooks';
import { useNavigate } from 'react-router-dom'
import AuthService from '../../services/AuthService';
import PrivateComponent from '../Authorization/PrivateComponent';
import { isAdmin } from '../../redux-state/slices/authSlice';
import { useTheme } from '@mui/material';

export const NAVBAR_HEIGHT = '70px'
export const NAVBAR_PHONE_HEIGHT = '60px'

export default function Navbar() {
    const user = useAppSelector(state => state.auth.user)
    const navigate = useNavigate()
    const onClick = () => navigate(`/`)
    const theme = useTheme()
    const phoneSize = useMediaQuery('(max-width:600px)');
    return (
        <AppBar position="fixed" sx={{pt: '.5em', pb: '.5em', height: {xs: NAVBAR_PHONE_HEIGHT, md: NAVBAR_HEIGHT}, color: 'white', background: theme.palette.gradient.main || theme.palette.primary.main}}>
            <Toolbar>
            
            <Typography variant={phoneSize ? 'body1' : 'h5'} component="div" sx={{ 
                flex: 1, 
                fontWeight: 800, 
                fontFamily: 'Inter', 
                textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                cursor: 'pointer' }}
                onClick={onClick}>
                Attendance Manager
            </Typography>
            <Box sx={{display: 'flex', alignItems: 'center', flex: {xs: 1, sm: 'unset'}}}>
                <Typography component="div" sx={{ textAlign: 'center', fontSize: {xs: '.7em', sm:'1em'},flexGrow: 1, fontWeight: 400, fontFamily: 'Inter'}}>
                    {user?.callName} <br/>{user?.scjNumber}
                </Typography>
                {/* <PrivateComponent isAuthorized={isAdmin}>
                    <Tooltip title="Role management">
                        <IconButton
                            onClick={() => {}}
                            color="inherit"
                            sx={{ ml: {sm: '10px'}, p: {xs: '0 0 0 0px', sm: '8px'} }}
                        >
                            <ManageAccountsIcon sx={{fontSize: {xs: '1em', sm:'2em'}, fontWeight: 200}}/>
                        </IconButton>
                    </Tooltip>
                </PrivateComponent> */}
                <Tooltip title="Logout">
                    <IconButton
                        onClick={AuthService.logout}
                        color="inherit"
                        sx={{ ml: {sm: '10px'}, p: {xs: '0 0 0 10px', sm: '8px'} }}
                    >
                        <LogoutIcon sx={{fontSize: {xs: '1em', sm:'2em'}, fontWeight: 200}}/>
                    </IconButton>
                </Tooltip>
            </Box>
            </Toolbar>
        </AppBar>
    );
}

import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import React from "react"

const CustomTooltip = ({ active, payload, label }: {active?: boolean, payload?: any[], label?: string} )=> {
    if (active && payload && payload.length) {
      const value = payload[0].value
      return (
        <Box sx={{background: 'white', p: '.5em 1em', opacity: '.8', borderRadius: '10px', border: 'solid black 1px'}}>
          <Typography fontWeight={600}>{label}</Typography>
          <Typography fontWeight={800} color='primary'>{`${value}${isNaN(value) ? "" : "%"}`}</Typography>
        </Box>
      );
    }
  
    return null;
};

export default CustomTooltip;
export enum Role  {
    ROLE_MEMBER = 1,
    ROLE_ADMIN = 2,
    ROLE_ATTENDANCE_ADMIN = 4
}

export default interface User {
    id: string
    scjNumber: string
    roles: {[role_name in keyof typeof Role]?: number}
    callName: string
    group: string
  }
  